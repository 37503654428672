import { getProtectedApi } from "api/common";
import type { UserSettings } from "components/hoc/user_settings/UserSettingsContextProvider";
export const getUserSettings = () =>
  getProtectedApi()
    .post<UserSettings>("/user/settings")
    .then((r) => r.data);
export const setUserSettings = (settings: UserSettings) =>
  getProtectedApi()
    .put<UserSettings>("/user/settings", settings)
    .then((r) => r.data);
