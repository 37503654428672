import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { CallBackProps, Step } from "react-joyride";

import { Button } from "@panel2/systail-ui/button";
import ConfirmationProvider from "components/hoc/confirmation/ConfirmationProvider";
import NotificationProvider from "components/hoc/notifications/NotificationProvider";
import QueryStateContextProvider from "components/hoc/query/QueryStateContextProvider";
import SnackMessageProvider from "components/hoc/snack_message/SnackMessageProvider";
import Notifications from "components/molecules/notifications/Notifications";
import Router from "components/router/Router";
import { createBrowserHistory } from "history";
import { clearTranslationCache } from "i18n";
import Prefetch from "prefetch";
import { Provider } from "provider";
import { unstable_HistoryRouter as BrowserRouter } from "react-router-dom";
import { HOME_PAGE } from "util/const";

const history = createBrowserHistory({ window });
const Joyride = lazy(() => import("react-joyride"));

const steps: Step[] = [
  {
    content: (
      <big>
        <strong>
          <span>{t`tour.beginning_message|Welcome to Systail Digital Signage admin panel`}</span>
        </strong>
      </big>
    ),
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: "center" as const,
    target: "body",
  },
  {
    content: (
      <strong>
        <span>{t`tour.account_modal|This is the user menu`}</span>
      </strong>
    ),
    target: ".tour-account-modal",
    placement: "left",
  },
  {
    content: (
      <strong>
        <span>{t`tour.logout_btn|You can log out here`}</span>
      </strong>
    ),
    target: ".tour-logout-btn",
  },
  {
    content: (
      <strong>
        <span>{t`tour.add_user|You can log in as a different user here`}</span>
      </strong>
    ),
    target: ".tour-add-user-btn",
  },
];

const Main = (): JSX.Element => {
  const [runTour, setRunTour] = useState<boolean>(false);
  const [tourStep, setTourStep] = useState<number>(0);
  const [online, setOnline] = useState<boolean>(window.navigator.onLine);

  useEffect(() => {
    const onOnline = () => setOnline(true);
    const onOffline = () => setOnline(false);
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);

    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, []);

  // useEffect(() => {
  //   // example
  //   // if (tourStep === 4) {
  //   //   const el = document.querySelector(".tour-add-user-btn>button") as HTMLElement
  //   //   if (el) {
  //   //     el.click()
  //   //   }
  //   // }
  // }, [tourStep]);

  const debugTranslations =
    sessionStorage.getItem("debugTranslations") === "true";
  const showingTranslationKeys =
    sessionStorage.getItem("showingTranslationKeys") === "true";

  return (
    <Prefetch>
      {debugTranslations && (
        <div className="flex w-full justify-between bg-rose-500">
          {showingTranslationKeys ? (
            <strong className="my-auto px-3 text-sm uppercase text-white">
              showing translation keys!
            </strong>
          ) : (
            <strong className="my-auto px-3 text-sm uppercase text-white">
              debugging translations
            </strong>
          )}

          <Button
            className="ml-auto rounded-none text-white hover:bg-rose-600"
            onClick={() => {
              if (showingTranslationKeys) {
                sessionStorage.removeItem("showingTranslationKeys");
              } else {
                sessionStorage.setItem("showingTranslationKeys", "true");
              }

              clearTranslationCache();
              window.location.reload();
            }}
          >
            {showingTranslationKeys
              ? "hide translation keys"
              : "show translation keys"}
          </Button>

          <Button
            className="rounded-none text-white hover:bg-rose-600"
            onClick={() => {
              sessionStorage.removeItem("translationDebug");
              window.location.reload();
            }}
          >
            turn off DEBUG
          </Button>
        </div>
      )}
      <Provider value={useCallback(() => setRunTour(true), [])}>
        {useMemo(
          () => (
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            <BrowserRouter basename={HOME_PAGE} history={history as any}>
              <QueryStateContextProvider>
                <NotificationProvider>
                  <SnackMessageProvider>
                    <ConfirmationProvider>
                      <Router />
                      <Notifications />
                    </ConfirmationProvider>
                  </SnackMessageProvider>
                </NotificationProvider>
              </QueryStateContextProvider>
            </BrowserRouter>
          ),
          []
        )}

        <Suspense>
          <Joyride
            run={runTour}
            steps={steps}
            stepIndex={tourStep}
            showProgress
            showSkipButton
            continuous
            hideCloseButton
            styles={{
              options: {
                arrowColor: "#fff",
                backgroundColor: "#fff",
                beaconSize: 36,
                overlayColor: "rgba(0, 0, 0, 0.5)",
                primaryColor: "#1d2a33",
                spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
                textColor: "#333",
                width: undefined,
                zIndex: 9000,
              },
            }}
            callback={(params: CallBackProps) => {
              if (params.status === "finished" || params.status === "skipped") {
                setRunTour(false);
              } else if (
                ["step:after", "error:target_not_found"].includes(params.type)
              ) {
                setTourStep(params.index + (params.action === "prev" ? -1 : 1));
              }
            }}
          />
        </Suspense>
      </Provider>

      {!online && (
        <small className="fixed bottom-0 flex h-[20px] w-full bg-rose-500">
          <strong className="m-auto text-white">{t`labels.offline|Offline`}</strong>
        </small>
      )}

      <small className="fixed bottom-0 right-0 text-dim opacity-[0.6] mr-5 mb-1">
        {t`labels.powered_by|Powered by`} systail
      </small>
    </Prefetch>
  );
};

export default Main;
