import { useMutation } from "@tanstack/react-query";
import { changePasswordRequired } from "api/user/get";
import Done from "components/atoms/icons/Done";
import Person from "components/atoms/icons/Person";
import { useNotifierCtx } from "components/hoc/notifications/provider";
import { useUserCtx } from "components/hoc/user/provider";
import ChangeFromControl from "components/molecules/change_form_control/ChangeFormControl";
import { useChangePasswordCtx } from "components/organisms/change_password/provider";
import { useSlug } from "provider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError } from "util/misc";

const ChangePasswordForm = (): JSX.Element => {
  const { user, logout } = useUserCtx();
  const { formOk, pass } = useChangePasswordCtx();
  const notify = useNotifierCtx();
  const navigate = useNavigate();
  const slug = useSlug();

  const mutation = useMutation({
    mutationFn: changePasswordRequired,
    onError: (error: Error) => {
      notifyError(
        notify,
        <span>{t`change.failed_password_change|Change password failed`}</span>,
        error
      );
    },
    onSuccess: () => {
      notify({
        title: t`labels.change_password_successful|Change password successful`,
        variant: "success",
        content: (
          <div className="flex">
            <Done size="32px" className="py-auto pr-2 text-green-500" />

            <span className="my-auto">
              <span>{t`labels.password_changed_successfully|Password changed successfully`}</span>
            </span>
          </div>
        ),
      });

      logout();

      navigate(`/${slug}/login`);
    },
  });

  const onSubmit = useCallback(() => {
    if (formOk && pass) {
      mutation.mutate(pass);
    }
  }, [mutation, pass, formOk]);

  return (
    <div className="flex h-full w-full">
      <div className="delayed-grow flex max-w-[1000px] grow">
        <div className="delayed-grow m-auto flex flex-col lg:ml-auto">
          <div className="rounded-t border-x-4 border-t-4 border-surface-variant bg-surface px-5 pb-3 pt-5 drop-shadow-xl">
            <h2 className="my-4 select-none text-4xl text-on-surface">
              <strong>
                <span>{t`titles.set_new_password|Set new password`}</span>
              </strong>
            </h2>
          </div>
          <div className="m-0 h-1" />
          <div className="rounded-b border-x-4 border-b-4 border-surface-variant bg-surface px-4 pb-3 pt-4 drop-shadow-xl">
            {user?.username && (
              <div className="flex">
                <Person size="24px" className="my-auto text-primary" />
                <span className="stale ml-2">{user.username}</span>
              </div>
            )}
            <ChangeFromControl
              loading={mutation.isPending}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
