import { useMutation, useQuery } from "@tanstack/react-query";
import {
  type ResetCheckResponse,
  resetCheck,
  resetConfirm,
} from "api/auth/login";

import { Spinner } from "@panel2/systail-ui/spinner";
import Done from "components/atoms/icons/Done";
import Person from "components/atoms/icons/Person";
import { useNotifierCtx } from "components/hoc/notifications/provider";
import ResetFormControl from "components/molecules/reset_form_control/ResetFormControl";
import ResetInvalidForm from "components/organisms/reset/ResetInvalidForm";
import { useResetCtx } from "components/organisms/reset/provider";
import useSetupThemedBackground from "components/pages/login/utils";
import { useSlowQueryStateWithNavigationChange } from "hooks/query/useSlowQueryStateWithNavigationChange";
import { useSlug } from "provider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError } from "util/misc";

const ResetForm = (): JSX.Element => {
  const slug = useSlug();
  const [{ token }] = useSlowQueryStateWithNavigationChange<{
    token?: string;
  }>();

  const { formOk, pass } = useResetCtx();
  const notify = useNotifierCtx();
  const navigate = useNavigate();

  const { data, isFetching } = useQuery<ResetCheckResponse, Error>({
    queryFn: () =>
      resetCheck({
        token: token ?? "",
      }),
    queryKey: ["resetCheck"],
  });

  const valid = data?.valid;

  const mutation = useMutation({
    mutationFn: resetConfirm,
    onError: (error: Error) => {
      notifyError(notify, <span>{t`reset.failed|Reset Failed`}</span>, error);
    },
    onSuccess: () => {
      notify({
        title: t`labels.reset_successful|Reset successful`,
        variant: "success",
        content: (
          <div className="flex">
            <Done size="32px" className="py-auto pr-2 text-green-500" />

            <span className="my-auto">
              <span>{t`labels.password_changed_successfully|Password changed successfully`}</span>
            </span>
          </div>
        ),
      });

      navigate(`/${slug}/login`);
    },
  });

  const onSubmit = useCallback(() => {
    if (formOk && token && pass) {
      mutation.mutate({
        token: token,
        password: pass,
      });
    }
  }, [mutation, pass, formOk, token]);

  useSetupThemedBackground();

  return (
    <div className="flex h-full w-full">
      <div className="delayed-grow flex max-w-[1000px] grow">
        {isFetching ? (
          <Spinner style={{ fontSize: "2rem" }} className="m-auto" />
        ) : !valid ? (
          <ResetInvalidForm />
        ) : (
          <div className="delayed-grow m-auto flex flex-col lg:ml-auto">
            <div className="rounded-t border-x-4 border-t-4 border-surface-variant bg-surface px-5 pb-3 pt-5 drop-shadow-xl">
              <h2 className="my-4 select-none text-4xl text-on-surface">
                <strong>
                  <span>{t`titles.set_new_password|Set new password`}</span>
                </strong>
              </h2>
            </div>
            <div className="m-0 h-1" />
            <div className="rounded-b border-x-4 border-b-4 border-surface-variant bg-surface px-4 pb-3 pt-4 drop-shadow-xl">
              {data.email && (
                <div className="flex">
                  <Person size="24px" className="my-auto text-primary" />
                  <span className="stale ml-2">{data.email}</span>
                </div>
              )}
              <ResetFormControl
                loading={mutation.isPending}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetForm;
