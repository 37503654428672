import { useSlug } from "provider";
import { useEffect } from "react";
import defaultBg from "util/default_bg";

const useSetupThemedBackground = () => {
  const slug = useSlug();

  useEffect(() => {
    const bg = document.getElementById("background");
    const forcedSlug = import.meta.env.VITE_FORCE_SLUG;
    const identifier = forcedSlug || slug;

    let bgTranslationMap: Record<string, string> = {};
    let styleTranslationMap: Record<string, string> = {};

    if (import.meta.env.VITE_SLUG_TO_LOGIN_BG_RESOURCE_DICT) {
      try {
        const parsed = JSON.parse(
          import.meta.env.VITE_SLUG_TO_LOGIN_BG_RESOURCE_DICT
        );

        if (
          typeof parsed === "object" &&
          Object.entries(parsed).every(
            ([k, v]) => typeof k === "string" && typeof v === "string"
          )
        ) {
          bgTranslationMap = parsed;
        }
      } catch {
        console.error(
          "Failed to parse VITE_SLUG_TO_LOGIN_BG_RESOURCE_DICT, expected a JSON object with string keys and string values"
        );
      }
    }

    if (import.meta.env.VITE_SLUG_TO_LOGIN_STYLE_RESOURCE_DICT) {
      try {
        const parsed = JSON.parse(
          import.meta.env.VITE_SLUG_TO_LOGIN_STYLE_RESOURCE_DICT
        );

        if (
          typeof parsed === "object" &&
          Object.entries(parsed).every(
            ([k, v]) => typeof k === "string" && typeof v === "string"
          )
        ) {
          styleTranslationMap = parsed;
        }
      } catch {
        console.error(
          "Failed to parse VITE_SLUG_TO_LOGIN_STYLE_RESOURCE_DICT, expected a JSON object with string keys and string values"
        );
      }
    }

    const bgResource = bgTranslationMap[identifier];
    const styleResource = styleTranslationMap[identifier];
    const controller = new AbortController();

    if (bg) {
      if (bgResource?.includes(".")) {
        const [file, ext] = bgResource.split(".", -1);
        const assetsUrlBase = `${import.meta.env.BASE_URL}/login/`.replace(
          "//",
          "/"
        );
        const assetUrl = `${assetsUrlBase}${file}.${ext}`;

        switch (ext) {
          case "svg":
            fetch(assetUrl, { signal: controller.signal })
              .then((res) => res.text())
              .then((data) => {
                bg.innerHTML = data;
              })
              .catch((err) => {
                console.error(err);
                bg.innerHTML = defaultBg;
              });
            break;

          case "png":
          case "jpg":
          case "jpeg":
          case "gif":
          case "webp":
            // bg.style.backgroundImage = `url(${assetUrl})`;
            bg.innerHTML = `<img id="background-resource" src="${assetUrl}" alt="background" />`;
            break;

          case "mp4":
          case "webm":
            bg.innerHTML = file.includes("loop")
              ? `<video id="background-resource" autoplay loop muted playsinline><source src="${assetUrl}" type="video/${ext}" /></video>`
              : `<video id="background-resource" autoplay muted playsinline><source src="${assetUrl}" type="video/${ext}" /></video>`;
            break;

          default:
            console.error(`Unsupported extension ${ext}`);
            bg.innerHTML = defaultBg;
        }
      } else {
        bg.innerHTML = defaultBg;
      }
    } else {
      console.error("Failed to find background element");
    }

    if (styleResource?.endsWith(".css")) {
      const assetsUrlBase = `${import.meta.env.BASE_URL}/login/`.replace(
        "//",
        "/"
      );
      const assetUrl = `${assetsUrlBase}${styleResource}`;

      fetch(assetUrl, { signal: controller.signal })
        .then((res) => res.text())
        .then((data) => {
          const style = document.createElement("style");
          style.innerHTML = data;
          document.head.appendChild(style);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (styleResource) {
      console.error("Unsupported style resource extension: ", styleResource);
    }

    return () => {
      controller.abort("aborted resource loading due to component unmount");
    };
  }, [slug]);
};

export default useSetupThemedBackground;
